import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const query = graphql`
    {
        allStrapiServiceSolution {
            nodes {
                slug
                icon
                shortText
                title
            }
        }
    }
`

const RelatedServices = () => {

    const data = useStaticQuery(query)
    const {
        allStrapiServiceSolution: { nodes: services },
    } = data
    
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Más de nuestras características</h2>
                </div>

                <div className="row">
                {
                    services.map((service, idx) => {
                        return(
                    <div className="col-lg-4 col-md-6 col-sm-6" key={idx}>
                        <div className="single-services-box ">
                            <div className="icon">
                                <i className={service.icon}></i>
                            </div>
                            <h3>
                                <Link to={`/solution/${service.slug}`}>
                                    {service.title}
                                </Link>
                            </h3>
                            <p>{service.shortText}</p>

                            <Link to={`/solution/${service.slug}`} className="read-more-btn">
                                Ver más <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                           )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default RelatedServices