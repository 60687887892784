import React from 'react'
import {Link} from 'gatsby'
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
    {
        allStrapiServiceSolution {
            nodes {
                slug
                title
            }
        }
    }
`

const ServiceSidebar = () => {

    const data = useStaticQuery(query)
    const {
        allStrapiServiceSolution: { nodes: services },
    } = data
    
    return (
        <div className="services-details-info">
            <ul className="services-list">
                {
                    services.map((service, idx) => {
                        return(
                            <li key={idx}>
                                <Link to={`/solution/${service.slug}`}>
                                    {service.title}
                                </Link>
                            </li>
                           )
                        })
                    }
            </ul>

{/*
            <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li>
                        <Link to="#">
                            PDF Download <i className='bx bxs-file-pdf'></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            Services Details.txt <i className='bx bxs-file-txt'></i>
                        </Link>
                    </li>
                </ul>
            </div>
*/}

            <div className="services-contact-info">
                <h3>Contacto</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Teléfono:</span>
                        <a href="tel:+525552523629">+(52) 55 5252 3629</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Ubicación:</span>
                        CDMX, México
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:contacto@domainmx.mx">contacto@domainmx.mx</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar